const { serviceHelper } = require('../serviceHelper');

async function getAllPauses(token) {
  return serviceHelper(`/pauses`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

async function getPausesBetweenHours(
  startTime,
  endTime,
  token,
  date = new Date(),
) {
  return serviceHelper(
    `/students/pause?startTime=${startTime}&endTime=${endTime}&date=${date}&active=true`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
}

async function getCurrentPauseByEmail(email, token) {
  return serviceHelper(`/pauses/current/${email}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

async function startPauseAPI(data, token) {
  return serviceHelper('/pauses/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
}

async function updatePauseAPI(pauseId, data, token) {
  return serviceHelper('/pauses/' + pauseId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
}

export {
  getAllPauses,
  getCurrentPauseByEmail,
  getPausesBetweenHours,
  startPauseAPI,
  updatePauseAPI,
};

const { serviceHelper } = require('../serviceHelper');

async function createPresence(token, data) {
  return serviceHelper('/presences', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
}

export { createPresence };

import React from 'react';
import PropTypes from 'prop-types';

import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import GlobalLogin from '../GlobalLogin/GlobalLogin';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      width: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    endIcons: {
      width: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

function GlobalAppBar({ toggleDrawer }) {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={toggleDrawer}
          className={classes.menuButton}
          aria-label={'menu-button'}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.endIcons}>
          <GlobalLogin />
        </div>
      </Toolbar>
    </AppBar>
  );
}

GlobalAppBar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};

export default GlobalAppBar;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';

import store from 'redux/store';

import { msalConfig } from 'service/Oauth2Config/microsoft/microsoftAuthConfig';

import 'index.css';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

window.store = store;

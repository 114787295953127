class LocalLogin {
  hasLocalUser() {
    return !!JSON.parse(localStorage.getItem('user'));
  }

  getLocalUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getLocalUserToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.payload) return '';
    return user.payload.token;
  }

  getLocalUserRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.payload) return '';
    return user.payload.refresh_token;
  }

  setLocalUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeLocalUser() {
    localStorage.removeItem('user');
  }
}

const instance = new LocalLogin();
export default instance;

import React from 'react';

import {createStyles, makeStyles} from "@mui/styles";

import ImageButton from "../../components/global/ImageButton/ImageButton";

const items = [
  {
    image_url: '/',
    title: 'Pause',
    url: '/pause',
  },
  {
    image_url: '/',
    title: 'Présence',
    url: '/presence',
  },
  {
    image_url: '/',
    title: 'Pause Summary',
    url: '/summary/pauses',
  },
  {
    image_url: '/',
    title: 'Global Summary',
    url: '/summary/stats',
  },
  {
    image_url: '/',
    title: '49-3',
    url: '/49-3',
  },
  {
    image_url: '/',
    title: 'Students',
    url: '/students',
  },
]

const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        padding: '20px',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
      }
    }))
function HomeView() {
  const classes = useStyles();

  return(
      <div className={classes.root}>
        {items.map((item, key) => <ImageButton key={key} item={item}/>)}
      </div>
  );
}

export default HomeView;

export function validateUsername(username) {
  return !(username === '');
}

export function validatePassword(password) {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasNonAlphas = /\W/.test(password);

  if (process.env.NODE_ENV === 'development') return true;

  if (password === '') {
    return false;
  } else if (password.length < 8) {
    return false;
  } else return hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphas === 4;
}

import { combineReducers } from 'redux';

import customisationReducer from './customisationReducer';
import loginReducer from './loginReducer';
import snackbarReducer from './snackbarReducer';

const rootReducer = combineReducers({
  loginReducer,
  snackbarReducer,
  customisationReducer,
});

export default rootReducer;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Edit, Save } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TableCell,
  TextField,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import { updatePauseAPI } from 'service/serviceHelper/request/pause';

const useStyles = makeStyles(() =>
  createStyles({
    cell: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      gap: '20px',
    },
    time: {
      display: 'flex',
      gap: '20px',
    },
    text: {
      width: '50px',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PauseTableCell({
  pause,
  email,
  token,
  logout,
  snackbarError,
  updatePauseTIme,
}) {
  const classes = useStyles();
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isInErrorSeconds, setIsInErrorSeconds] = useState(false);
  const [isInErrorMinutes, setIsInErrorMinutes] = useState(false);

  const onClickHandle = () => {
    if (isEditing) {
      if (!minutes) setMinutes('0');
      if (!seconds) setSeconds('0');
      if (
        (!/^\d+$/.test(minutes) && minutes) ||
        (!/^\d+$/.test(seconds) && seconds)
      ) {
        setIsInErrorSeconds(!/^\d+$/.test(seconds));
        setIsInErrorMinutes(!/^\d+$/.test(minutes));
        return;
      }
      setIsEditing(false);
      const time =
        ((parseInt(minutes) || 0) * 60 + (parseInt(seconds) || 0)) * 1000;
      const oldTime =
        (Math.round(pause.endDate / 1000) -
          Math.round(pause.startDate / 1000)) *
        1000;
      const newGap = oldTime - time;
      pause.endDate = new Date(pause.endDate - newGap);
      updatePauseAPI(
        pause.id,
        {
          email,
          endDate: pause.endDate,
          startDate: pause.startDate,
        },
        token,
      )
        .then(() => {
          pause.time =
            ((parseInt(minutes) || 0) * 60 + (parseInt(seconds) || 0)) * 1000;
          updatePauseTIme(pause);
        })
        .catch(({ code, msg }) => {
          snackbarError(msg);
          if (code === 401) logout();
        });
    } else {
      setIsEditing(true);
    }
    setIsInErrorSeconds(false);
    setIsInErrorMinutes(false);
  };

  useEffect(() => {
    if (pause) {
      const timestamp = pause.time / 1000;
      setMinutes(Math.floor(timestamp / 60).toString());
      setSeconds(Math.floor(timestamp % 60).toString());
    }
  }, [pause]);

  return (
    <TableCell align="right">
      {pause && pause.endDate instanceof Date && !isNaN(pause.endDate) ? (
        <div className={classes.cell}>
          <IconButton onClick={onClickHandle}>
            {isEditing ? <Save /> : <Edit />}
          </IconButton>
          <div className={classes.time}>
            {isEditing ? (
              <TextField
                onChange={({ target: { value } }) => setMinutes(value)}
                error={isInErrorMinutes}
                className={classes.text}
                value={minutes}
                size={'small'}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            ) : (
              minutes + 'm'
            )}
            {isEditing ? (
              <TextField
                onChange={({ target: { value } }) => setSeconds(value)}
                error={isInErrorSeconds}
                className={classes.text}
                value={seconds}
                size={'small'}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">s</InputAdornment>
                  ),
                }}
              />
            ) : (
              seconds + 's'
            )}
          </div>
        </div>
      ) : undefined}
    </TableCell>
  );
}

PauseTableCell.propTypes = {
  pause: PropTypes.object,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  snackbarError: PropTypes.func.isRequired,
  updatePauseTIme: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  PauseTableCell,
);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Alert, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';

import { snackbarReset } from 'redux/actions/snackbarAction';
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from 'redux/constants/action-types';

const styles = () => ({});

function mapStateToProps(state) {
  return {
    open: state.snackbarReducer.open,
    status: state.snackbarReducer.status,
    message: state.snackbarReducer.message,
  };
}

function GlobalSnackbar({ snackbarReset, status, open, message }) {
  const [severity, setSeverity] = useState('info');

  const handleClose = () => {
    snackbarReset();
  };

  useEffect(() => {
    switch (status) {
      case SNACKBAR_SUCCESS:
        setSeverity('success');
        break;
      case SNACKBAR_ERROR:
        setSeverity('error');
        break;
      case SNACKBAR_WARNING:
        setSeverity('warning');
        break;
      default:
        setSeverity('info');
    }
  }, [status]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

GlobalSnackbar.propTypes = {
  snackbarReset: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, { snackbarReset })(GlobalSnackbar),
);

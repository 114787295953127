const serverUrl = process.env.REACT_APP_SERVER_HOSTNAME || 'localhost:8000';

export async function serviceHelper(URL, content = {}) {
  URL = `${serverUrl}/api` + URL;

  return fetch(URL, content)
    .then((response) => {
      let funcToCall;
      switch (response.headers.get('content-type')) {
        case 'application/json;charset=UTF-8':
        case 'application/json; charset=utf-8':
        case 'application/json':
          funcToCall = 'json';
          break;
        case 'application/octet-stream':
          funcToCall = 'blob';
          break;
        default:
          funcToCall = 'text';
      }
      return response[funcToCall]().then((data) => ({ data, response }));
    })
    .then(({ data, response }) => {
      if (!response.ok) {
        return Promise.reject({ data: data, status: response.status });
      } else if (response.status >= 200 && response.status <= 299) {
        return data;
      }
      return Promise.reject({ data: data, status: response.status });
    })
    .catch((err) => {
      if (err.status === 401) {
        return Promise.reject({ code: 401, msg: 'You are not connected' });
      } else {
        return Promise.reject({
          code: err.status,
          msg: err.status + ': Contact an administrator with the error code',
        });
      }
    });
}

import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function mapStateToProps(state) {
  return {
    logged: state.loginReducer.logged,
  };
}

function AdminRoute({ logged, children }) {
  const location = useLocation();

  return (
    <div>
      {logged ? (
        children
      ) : (
        <Navigate to="/login" replace state={{ from: location }} />
      )}
    </div>
  );
}

AdminRoute.propTypes = {
  logged: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default connect(mapStateToProps, {})(AdminRoute);

import React from 'react';

import { Card } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Login from 'components/authentication/Login/Login';
import GlobalSnackbar from 'components/global/GlobalSnackbar/GlobalSnackbar';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      background:
        'url("https://www.epitech.eu/fr/wp-content/uploads/Epitech-Technology-Campus-Bordeaux-2.jpg") no-repeat center center fixed',
      backgroundSize: 'cover',
      padding: '15vh 35vw',
      height: '70vh',
      [theme.breakpoints.down('md')]: {
        padding: '15vh 10vw',
        height: '70vh',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        minWidth: '600px',
      },
      height: '100%',
      display: 'flex',
      border: '1px solid rgba(200, 200, 200, 1) !important',
      boxShadow: '15px 15px 10px rgba(0, 0, 0, 0.5) !important',
    },
    info: {
      width: '100%',
      display: 'flex',
    },
  }),
);

function LoginView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.info}>
          <Login />
        </div>
      </Card>
      <GlobalSnackbar />
    </div>
  );
}

export default LoginView;

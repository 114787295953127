import {
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
  SNACKBAR_RESET,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from '../constants/action-types';

export function snackbarSuccess(message) {
  return function (dispatch) {
    return dispatch({ type: SNACKBAR_SUCCESS, payload: message });
  };
}
export function snackbarError(message) {
  return function (dispatch) {
    return dispatch({ type: SNACKBAR_ERROR, payload: message });
  };
}
export function snackbarWarning(message) {
  return function (dispatch) {
    return dispatch({ type: SNACKBAR_WARNING, payload: message });
  };
}
export function snackbarInfo(message) {
  return function (dispatch) {
    return dispatch({ type: SNACKBAR_INFO, payload: message });
  };
}
export function snackbarReset() {
  return function (dispatch) {
    return dispatch({ type: SNACKBAR_RESET, payload: '' });
  };
}

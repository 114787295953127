import Customisation from 'service/customisation/customisation';

import {
  CUSTOMISATION_DARK_MODE,
  CUSTOMISATION_LANGUAGE,
} from '../constants/action-types';

const initialState = {
  darkMode: Customisation.isDarkMode(),
  languageIso2: Customisation.getLanguage(),
};

function customisationReducer(state = initialState, action) {
  if (action.type === CUSTOMISATION_DARK_MODE) {
    Customisation.toggleDarkMode(action.payload);
    return Object.assign({}, state, {
      darkMode: action.payload,
    });
  }

  if (action.type === CUSTOMISATION_LANGUAGE) {
    Customisation.setLanguage(action.payload);
    return Object.assign({}, state, {
      languageIso2: action.payload,
    });
  }

  return state;
}

export default customisationReducer;

import React from 'react';
import PropTypes from 'prop-types';

import { Search as SearchIcon } from '@mui/icons-material';
import { Card, InputBase, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 80%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    number: {
      alignItems: 'center',
    },
    searchBar: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifySelf: 'right',
      width: '100%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  }),
);

function SearchBar({ update, number, name }) {
  const classes = useStyles();

  const handleTextChange = (event) => {
    update(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.number}>
        <Typography align="center" color={'textPrimary'}>
          {number} {name}
          {number > 1 ? 's' : ''}
        </Typography>
      </div>
      <Card component="form" className={classes.searchBar}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={handleTextChange}
        />
        <SearchIcon className={classes.iconButton} />
      </Card>
    </div>
  );
}

SearchBar.propTypes = {
  update: PropTypes.func.isRequired,
  number: PropTypes.number,
  name: PropTypes.string,
};

export default SearchBar;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import PauseAction from 'components/pause/PauseAction/PauseAction';
import SearchHeader from 'components/SearchHeader/SearchHeader';
import StudentCard from 'components/StudentCard/StudentCard';

import { SocketContext } from 'context/socket';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import {
  searchParamsToObject,
  updateSearchParams,
} from 'service/searchParam/searchParam';
import {
  getPausesBetweenHours,
  startPauseAPI,
  updatePauseAPI,
} from 'service/serviceHelper/request/pause';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      gap: '20px',
    },
    pauseDiv: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    studentList: {
      display: 'grid',
      gridGap: '20px',
      gridTemplateColumns: '1fr',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    divider: {
      height: '10px',
      backgroundColor: '#111',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PauseView({ token, logout }) {
  const classes = useStyles();
  const socket = useContext(SocketContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [inPauseStudent, setInPauseStudent] = useState(0);
  const date = useMemo(() => new Date(), []);

  useEffect(() => {
    const startTime = date.getHours() >= 13 ? 13 : 0;
    const endTime = date.getHours() >= 13 ? 23 : 13;

    getPausesBetweenHours(startTime, endTime, token)
      .then((res) => setStudents(res))
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  }, [token, date, logout]);

  useEffect(() => {
    const { search } = searchParamsToObject(searchParams);

    setFilteredStudents(
      students.filter((student) =>
        (student.firstname + ' ' + student.lastname).includes(search || ''),
      ),
    );
  }, [searchParams, students]);

  useEffect(() => {
    setInPauseStudent(students.filter((s) => s.currentPause).length);
  }, [students]);

  useEffect(() => {
    socket.on('start_pause', (data) => {
      data = JSON.parse(data);
      const _students = students.map((s) => {
        if (s.email === data.email) {
          s.currentPause = true;
          s.pauses.push(data);
        }
        return s;
      });
      setStudents(_students);
      setInPauseStudent(inPauseStudent + 1);
    });
    socket.on('update_pause', (data) => {
      data = JSON.parse(data);
      const _students = students.map((s) => {
        if (s.email === data.email) {
          s.currentPause = false;
          const pos = s.pauses.findIndex((p) => p._id === data._id);
          if (pos !== undefined) s.pauses[pos] = data;
        }
        return s;
      });
      setStudents(_students);
      setInPauseStudent(inPauseStudent - 1);
    });

    return () => {
      socket.off('start_pause');
      socket.off('update_pause');
    };
  }, [socket, students, inPauseStudent]);

  const startPause = async (data) => {
    startPauseAPI(data, token).catch(({ code, msg }) => {
      snackbarError(msg);
      if (code === 401) logout();
    });
  };

  const endPause = async (data, pauseId) => {
    updatePauseAPI(pauseId, data, token).catch(({ code, msg }) => {
      snackbarError(msg);
      if (code === 401) logout();
    });
  };

  const studentCard = (student) => {
    return (
      <StudentCard student={student} key={student.email}>
        {date.getHours() >= 13 ? (
          <div>
            <PauseAction
              email={student.email}
              date={date}
              pause={student.pauses[0]}
              isInPause={student.currentPause}
              startPause={startPause}
              endPause={endPause}
            />
            <PauseAction
              email={student.email}
              date={date}
              pause={student.pauses[1]}
              isInPause={student.currentPause}
              startPause={startPause}
              endPause={endPause}
            />
          </div>
        ) : (
          <PauseAction
            email={student.email}
            date={date}
            pause={student.pauses[0]}
            isInPause={student.currentPause}
            startPause={startPause}
            endPause={endPause}
          />
        )}
      </StudentCard>
    );
  };

  const updateQueryParams = (key, value) => {
    updateSearchParams(key, value, searchParams, setSearchParams);
  };

  return (
    <div className={classes.root}>
      <SearchHeader
        filterFunction={(value) =>
          updateQueryParams('search', value.toLowerCase())
        }
        date={date}
        rightComponent={
          <Typography variant={'h6'} align={'center'}>
            Student in pause: {inPauseStudent}
          </Typography>
        }
      />
      {inPauseStudent > 0 ? (
        <div className={classes.pauseDiv}>
          <div className={classes.studentList}>
            {filteredStudents.map((student) =>
              student.currentPause ? studentCard(student) : null,
            )}
          </div>
          <div className={classes.divider} />
        </div>
      ) : null}
      <div className={classes.studentList}>
        {filteredStudents.map((student) =>
          !student.currentPause ? studentCard(student) : null,
        )}
      </div>
    </div>
  );
}

PauseView.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(PauseView);

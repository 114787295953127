import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { login } from 'redux/actions/loginAction';
import { snackbarError, snackbarSuccess } from 'redux/actions/snackbarAction';

import { loginRequest } from 'service/Oauth2Config/microsoft/microsoftAuthConfig';
import { externalLoginRequest } from 'service/serviceHelper/request/authentication';

const MicrosoftLoginButton = ({ snackbarError, snackbarSuccess, login }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const {
          accessToken,
          account: { username },
        } = res;
        return externalLoginRequest(username, accessToken, 'microsoft');
      })
      .then((res) => {
        snackbarSuccess('Connected!');
        login(res, true);
        navigate('/');
      })
      .catch((err) => snackbarError(err.message));
  };

  return (
    <Button
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faMicrosoft} />}
      onClick={() => handleLogin()}
      style={{ background: '#2F2F2F' }}
    >
      Sign in with Microsoft
    </Button>
  );
};

MicrosoftLoginButton.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  snackbarSuccess: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

export default connect(null, { snackbarError, snackbarSuccess, login })(
  MicrosoftLoginButton,
);

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MICROSOFT_APPLICATION_ID}`,
    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: `${process.env.REACT_APP_MICROSOFT_CLOUD_INSTANCE_ID}/${process.env.REACT_APP_MICROSOFT_TENANT_INFO}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: 'sessionStorage',
    // Set this to "true" if you are having issues on IE11 or Edge
    storeAuthStateInCookie: false,
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: `${process.env.REACT_APP_MICROSOFT_GRAPH_API}/v1.0/me`,
};

class Customisation {
  isDarkMode() {
    const darkModeObj = JSON.parse(localStorage.getItem('darkMode'));
    return darkModeObj ? darkModeObj.darkMode : false;
  }

  toggleDarkMode(value) {
    localStorage.setItem('darkMode', JSON.stringify({ darkMode: value }));
  }

  getLanguage() {
    const languageObj = JSON.parse(localStorage.getItem('language'));
    return languageObj ? languageObj.iso2 : 'US';
  }

  setLanguage(value) {
    localStorage.setItem('language', JSON.stringify({ iso2: value }));
  }
}

const instance = new Customisation();
export default instance;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import { timestampToStr } from 'service/date/timestampToStr';

import PauseTableCell from './PauseTableCell/PauseTableCell';

function PauseTableRow({ student }) {
  const [amPause, setAmPause] = useState(student.amPause);
  const [pmPause1, setPmPause1] = useState(student.pmPause1);
  const [pmPause2, setPmPause2] = useState(student.pmPause2);
  const [totalTime, setTotalTime] = useState(0);
  const [numberOfPause, setNumberOfPause] = useState(0);
  const [averageTime, setAverageTime] = useState(0);

  const updatePauseTIme = (newPause) => {
    if (newPause.id === amPause?.id) setAmPause(newPause);
    if (newPause.id === pmPause1?.id) setPmPause1(newPause);
    if (newPause.id === pmPause2?.id) setPmPause2(newPause);
    updateTotalTime();
  };

  const updateTotalTime = () => {
    setTotalTime(
      (amPause ? amPause.time : 0) +
        (pmPause1 ? pmPause1.time : 0) +
        (pmPause2 ? pmPause2.time : 0),
    );
    setNumberOfPause(
      (amPause ? 1 : 0) + (pmPause1 ? 1 : 0) + (pmPause2 ? 1 : 0),
    );
  };

  useEffect(() => {
    setAmPause(student.amPause);
    setPmPause1(student.pmPause1);
    setPmPause2(student.pmPause2);
  }, [student]);

  useEffect(() => {
    updateTotalTime();
  }, [amPause, pmPause1, pmPause2]);

  useEffect(() => {
    setAverageTime(numberOfPause > 0 ? totalTime / numberOfPause : 0);
  }, [totalTime, numberOfPause]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {student.email}
      </TableCell>
      <TableCell align="right">{timestampToStr(totalTime)}</TableCell>
      <TableCell align="right">{timestampToStr(averageTime)}</TableCell>
      <PauseTableCell
        pause={amPause}
        email={student.email}
        updatePauseTIme={updatePauseTIme}
      />
      <PauseTableCell
        pause={pmPause1}
        email={student.email}
        updatePauseTIme={updatePauseTIme}
      />
      <PauseTableCell
        pause={pmPause2}
        email={student.email}
        updatePauseTIme={updatePauseTIme}
      />
    </TableRow>
  );
}

PauseTableRow.propTypes = {
  student: PropTypes.shape({
    email: PropTypes.string.isRequired,
    amPause: PropTypes.object,
    pmPause1: PropTypes.object,
    pmPause2: PropTypes.object,
  }).isRequired,
};

export default PauseTableRow;

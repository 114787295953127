import LocalLogin from 'service/login/localLogin';

import { LOGIN, LOGOUT, REGISTER } from '../constants/action-types';

export function login(user, isSave = false) {
  if (isSave) LocalLogin.setLocalUser(user);

  return function (dispatch) {
    return dispatch({
      type: LOGIN,
      payload: {
        logged: true,
        token: user.payload.token,
        refresh: user.payload.refresh_token,
      },
    });
  };
}

export function register(user, isSave = false) {
  if (isSave) LocalLogin.setLocalUser(user);

  return function (dispatch) {
    return dispatch({
      type: REGISTER,
      payload: {
        logged: true,
        token: user.payload.token,
        refresh: user.payload.refresh_token,
      },
    });
  };
}

export function logout() {
  LocalLogin.removeLocalUser();

  return function (dispatch) {
    return dispatch({ type: LOGOUT });
  };
}

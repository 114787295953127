import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import PauseTableRow from 'components/summary/PauseTableRow/PauseTableRow';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import { getPausesBetweenHours } from 'service/serviceHelper/request/pause';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      gap: '20px',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PauseSummaryView({ token, logout, snackbarError }) {
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [date, setDate] = useState(undefined);

  useEffect(() => {
    setDate(new Date());
  }, []);

  useEffect(() => {
    if (!date) return;
    getPausesBetweenHours(0, 23, token, date)
      .then((res) => {
        const studentsList = res.map(
          ({ email, firstname, lastname, pauses }) => {
            const new_student = {
              email,
              firstname,
              lastname,
              amPause: undefined,
              pmPause1: undefined,
              pmPause2: undefined,
            };
            pauses.forEach(({ startDate, endDate, _id }) => {
              startDate = new Date(startDate);
              endDate = new Date(endDate);
              const time = endDate - startDate;
              if (startDate.getHours() < 13)
                new_student.amPause = { time, id: _id, startDate, endDate };
              else if (startDate.getHours() >= 13 && !new_student.pmPause1)
                new_student.pmPause1 = { time, id: _id, startDate, endDate };
              else new_student.pmPause2 = { time, id: _id, startDate, endDate };
            });
            return new_student;
          },
        );
        setStudents(studentsList);
      })
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  }, [date]);

  const updateDate = (number) => {
    const tmp = new Date(date);
    tmp.setDate(tmp.getDate() + number);
    setDate(tmp);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton onClick={() => updateDate(-1)}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography variant={'h4'} align={'center'}>
          {date?.toLocaleDateString()}
        </Typography>
        <IconButton onClick={() => updateDate(1)}>
          <ArrowForwardIos />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Login</TableCell>
              <TableCell align="right">Total Pause</TableCell>
              <TableCell align="right">Pause Moyenne</TableCell>
              <TableCell align="right">Pause Matin</TableCell>
              <TableCell align="right">Pause Aprèm 1</TableCell>
              <TableCell align="right">Pause Aprèm 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <PauseTableRow key={student.email} student={student} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

PauseSummaryView.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  PauseSummaryView,
);

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { words } from 'capitalize';
import Papa from 'papaparse';
import PropTypes from 'prop-types';

import { Save } from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import UploadButton from 'components/global/UploadButton/UploadButton';

import { logout } from 'redux/actions/loginAction';
import { snackbarError, snackbarSuccess } from 'redux/actions/snackbarAction';

import {
  createStudentsFromEmail,
  getAllStudents,
  updateStudent,
} from 'service/serviceHelper/request/student';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: theme.spacing(2),
    },
    action: {
      margin: theme.spacing(1),
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    content: {
      paddingTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      gridGap: theme.spacing(2),
    },
    card: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    archive: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      height: '100%',
      width: '100%',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function StudentView({ token, snackbarError, snackbarSuccess, logout }) {
  const classes = useStyles();
  const [json, setJson] = useState('');
  const [filename, setFilename] = useState('');
  const [students, setStudents] = useState([]);

  const setFileFromUpload = useCallback(
    (file, fileData) => {
      const { data, errors } = Papa.parse(fileData, {
        header: true,
        transformHeader: (h) => h.toLowerCase(),
      });
      if (data && data.length > 0 && (data[0].login || data[0].email)) {
        snackbarSuccess('CSV parsed!');
        setFilename(file.name);
        setJson(JSON.stringify(data));
      } else if (errors && errors.length > 0) {
        snackbarError('Use CSV file: ' + errors[0].message);
      }
    },
    [snackbarError, snackbarSuccess],
  );

  useEffect(() => {
    getAllStudents(token)
      .then((res) => setStudents(res))
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  }, [token]);

  const saveStudentsList = () => {
    createStudentsFromEmail(token, json)
      .then((res) => {
        setFilename('');
        setJson('');
        snackbarSuccess(`Added ${res.length} students`);
        setStudents(
          students.concat(res).sort((a, b) => (a.email > b.email ? 1 : -1)),
        );
      })
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  };

  const handleSwitch = (value, email) => {
    const student = students.find((s) => s.email === email);
    if (!student) return;
    student.archived = value;
    updateStudent(token, email, student)
      .then((res) => {
        const tmpStudents = students.filter((s) => s.email !== email);
        tmpStudents.push(res);
        setStudents(tmpStudents.sort((a, b) => (a.email > b.email ? 1 : -1)));
      })
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.action}>
        <UploadButton
          variant={'contained'}
          color={'primary'}
          title={'upload'}
          sendFileData={setFileFromUpload}
        />
        <Typography align={'center'} variant={'h5'}>
          {filename}
        </Typography>
        <Button
          disabled={!json}
          variant={'contained'}
          color={'success'}
          startIcon={<Save />}
          onClick={saveStudentsList}
        >
          Save Student List
        </Button>
      </div>
      <TextField
        multiline
        fullWidth
        value={json}
        maxRows={10}
        placeholder={`[{"email":"firstname.lastname@epitech.eu"}]`}
        onChange={({ target }) => setJson(target.value)}
      />
      <Divider variant={'fullWidth'} style={{ borderColor: '#000' }} />
      <div className={classes.content}>
        {students.map((student) => (
          <Card key={student.email} className={classes.card}>
            <div>
              <Typography variant={'h6'}>{student.email}</Typography>
              <Typography variant={'subtitle1'}>
                {words(student.firstname)} {student.lastname.toUpperCase()}
              </Typography>
            </div>
            <FormGroup className={classes.archive}>
              <FormControlLabel
                control={<Switch checked={student.archived} />}
                label="Sortie"
                onChange={(event, value) => handleSwitch(value, student.email)}
              />
            </FormGroup>
          </Card>
        ))}
      </div>
    </div>
  );
}

StudentView.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  snackbarSuccess: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  snackbarError,
  snackbarSuccess,
  logout,
})(StudentView);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Upload as UploadIcon } from '@mui/icons-material';
import { Button, Fab } from '@mui/material';

function UploadButton({ color, variant, title, isFab, sendFileData }) {
  const fileInput = useRef();
  const fileReader = new FileReader();
  const [file, setFile] = useState(undefined);

  const handleFileReader = () => {
    if (file) {
      const data = fileReader.result;
      sendFileData(file, data);
    }
  };

  useEffect(() => {
    if (file) {
      fileReader.onloadend = handleFileReader;
      fileReader.readAsText(file);
    }
  }, [file]);

  return (
    <label htmlFor="upload-photo">
      <input
        style={{ display: 'none' }}
        id="upload-photo"
        name="upload-photo"
        type="file"
        onChange={() => setFile(fileInput.current.files[0])}
        ref={fileInput}
      />
      {isFab ? (
        <Fab color={color} variant={variant} component="span">
          <UploadIcon />
        </Fab>
      ) : (
        <Button
          color={color}
          variant={variant}
          component="span"
          startIcon={<UploadIcon />}
        >
          {title}
        </Button>
      )}
    </label>
  );
}

UploadButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'info',
    'success',
  ]).isRequired,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']).isRequired,
  title: PropTypes.string,
  isFab: PropTypes.bool,
  sendFileData: PropTypes.func.isRequired,
};

export default UploadButton;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

function PauseActionButton({
  email,
  isInPause,
  isInCurrentPause,
  totalPauseTime,
  startPause,
  endPause,
  pauseStartTime,
  pauseId,
}) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => setDisabled(totalPauseTime > 0), [totalPauseTime]);

  return (
    <div>
      {isInPause && !disabled ? (
        <Button
          variant={'contained'}
          onClick={() =>
            endPause(
              { email, startDate: pauseStartTime, endDate: new Date() },
              pauseId,
            )
          }
        >
          End Pause
        </Button>
      ) : (
        <Button
          variant={'contained'}
          onClick={() =>
            startPause({
              email: email,
              startDate: new Date(),
              endDate: undefined,
            })
          }
          disabled={disabled || isInCurrentPause}
        >
          Start Pause
        </Button>
      )}
    </div>
  );
}

PauseActionButton.propTypes = {
  email: PropTypes.string.isRequired,
  isInPause: PropTypes.bool.isRequired,
  isInCurrentPause: PropTypes.bool.isRequired,
  totalPauseTime: PropTypes.number.isRequired,
  startPause: PropTypes.func.isRequired,
  endPause: PropTypes.func.isRequired,
  pauseStartTime: PropTypes.instanceOf(Date),
  pauseId: PropTypes.string,
};

export default PauseActionButton;

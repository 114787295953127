import {
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
  SNACKBAR_RESET,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from '../constants/action-types';

const initialState = {
  open: false,
  status: '',
  message: '',
};

export default function snackbarReducer(state = initialState, action) {
  if (action.type === SNACKBAR_SUCCESS) {
    return Object.assign({}, state, {
      open: true,
      status: SNACKBAR_SUCCESS,
      message: action.payload,
    });
  }
  if (action.type === SNACKBAR_ERROR) {
    return Object.assign({}, state, {
      open: true,
      status: SNACKBAR_ERROR,
      message: action.payload,
    });
  }
  if (action.type === SNACKBAR_WARNING) {
    return Object.assign({}, state, {
      open: true,
      status: SNACKBAR_WARNING,
      message: action.payload,
    });
  }
  if (action.type === SNACKBAR_INFO) {
    return Object.assign({}, state, {
      open: true,
      status: SNACKBAR_INFO,
      message: action.payload,
    });
  }
  if (action.type === SNACKBAR_RESET) {
    return Object.assign({}, state, {
      open: false,
      status: SNACKBAR_RESET,
      message: '',
    });
  }
  return state;
}

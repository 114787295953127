import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { faFirstOrder } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faBook,
  faChartLine,
  faHome,
  faPauseCircle,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DrawerItem from './DrawerItem/DrawerItem';

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: '250px',
    },
  }),
);

function mapStateToProps(state) {
  return {
    logged: state.loginReducer.logged,
  };
}

function GlobalDrawer({ toggleDrawer, open }) {
  const classes = useStyles();
  const [openAL, setOpenAL] = useState(false);

  const handleClick = () => {
    setOpenAL(!openAL);
  };

  return (
    <Drawer
      anchor={'left'}
      open={open}
      onClose={toggleDrawer}
      className={classes.drawer}
    >
      <List className={classes.drawer}>
        <DrawerItem
          name={'Home'}
          link={'/'}
          icon={<FontAwesomeIcon icon={faHome} size="lg" />}
          toggleDrawer={toggleDrawer}
        />
        <Divider />
        <DrawerItem
          name={'Pause'}
          link={'/pause'}
          icon={<FontAwesomeIcon icon={faPauseCircle} size="lg" />}
          toggleDrawer={toggleDrawer}
        />
        <Divider />
        <DrawerItem
          name={'Presence'}
          link={'/presence'}
          icon={<FontAwesomeIcon icon={faAddressBook} size="lg" />}
          toggleDrawer={toggleDrawer}
        />
        <Divider />
        <div>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faBook} size="lg" />
            </ListItemIcon>
            <ListItemText primary="Summary" />
            {openAL ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openAL} timeout="auto" unmountOnExit>
            <DrawerItem
              name={'Pauses'}
              link={'/summary/pauses'}
              icon={<FontAwesomeIcon icon={faPauseCircle} size="lg" />}
              toggleDrawer={toggleDrawer}
              nested
            />
            <DrawerItem
              name={'Global'}
              link={'/summary/stats'}
              icon={<FontAwesomeIcon icon={faChartLine} size="lg" />}
              toggleDrawer={toggleDrawer}
              nested
            />
          </Collapse>
          <Divider />
        </div>
        <DrawerItem
          name={'49:3'}
          link={'/49-3'}
          icon={<FontAwesomeIcon icon={faFirstOrder} size="lg" />}
          toggleDrawer={toggleDrawer}
        />
        <Divider />
        <DrawerItem
          name={'Students'}
          link={'/students'}
          icon={<FontAwesomeIcon icon={faUserGraduate} size="lg" />}
          toggleDrawer={toggleDrawer}
        />
      </List>
    </Drawer>
  );
}

GlobalDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {})(GlobalDrawer);

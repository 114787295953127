import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import { createPresence } from '../../../service/serviceHelper/request/presence';

const useStyles = makeStyles(() =>
  createStyles({
    action: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '10px',
      padding: '10px',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PresenceAction({ email, moment, date, token, status }) {
  const classes = useStyles();

  const updatePresence = (new_status) => {
    const data = {
      email: email,
      status: new_status,
      date: date,
      moment: moment,
    };

    createPresence(token, data).catch(({ code, msg }) => {
      snackbarError(msg);
      if (code === 401) logout();
    });
  };

  return (
    <div className={classes.action}>
      <Button
        variant={'contained'}
        color={'success'}
        disabled={status === 'present'}
        onClick={() => updatePresence('present')}
      >
        Present
      </Button>
      <Button
        variant={'contained'}
        color={'error'}
        disabled={status === 'absent'}
        onClick={() => updatePresence('absent')}
      >
        Absent
      </Button>
      <Button
        variant={'contained'}
        color={'warning'}
        disabled={status === 'retard'}
        onClick={() => updatePresence('retard')}
      >
        Retard
      </Button>
      <Button
        variant={'contained'}
        color={'info'}
        disabled={status === 'excuse'}
        onClick={() => updatePresence('excuse')}
      >
        Excusé
      </Button>
    </div>
  );
}

PresenceAction.propTypes = {
  email: PropTypes.string.isRequired,
  moment: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  token: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  PresenceAction,
);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Divider, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { login } from 'redux/actions/loginAction';
import { snackbarError, snackbarSuccess } from 'redux/actions/snackbarAction';

import { loginRequest } from 'service/serviceHelper/request/authentication';
import {
  validatePassword,
  validateUsername,
} from 'service/validator/authFormValidator';

import MicrosoftLoginButton from '../MicrosoftLoginButton/MicrosoftLoginButton';

const useStyles = makeStyles(() =>
  createStyles({
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      margin: 'auto',
      gap: '20px',
    },
    loginHeader: {
      width: '80%',
    },
    textField: {
      width: '80%',
    },
    loginButton: {
      width: '80%',
    },
  }),
);

function mapStateToProps(state) {
  return {
    logged: state.loginReducer.logged,
  };
}

function Login({ snackbarError, snackbarSuccess, login, logged }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const origin = location.state?.from?.pathname || '/';

  const loginClick = () => {
    const error1 = !validateUsername(username);
    setUsernameError(error1);
    const error2 = !validatePassword(password);
    setPasswordError(error2);

    if (error1) {
      snackbarError('Invalid Username');
    } else if (error2) {
      snackbarError(
        'Password must be 8 characters long, numbers, uppercase and lowercase letters',
      );
    } else {
      loginRequest(username, password)
        .then((res) => {
          snackbarSuccess('Connected');
          login(res, true);
          navigate(origin);
        })
        .catch(() => snackbarError('Invalid Username or Password'));
    }
  };

  useEffect(() => {
    if (logged) navigate(origin);
  }, [logged]);

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginHeader}>
        <Typography variant={'h3'} align={'left'}>
          Welcome!
        </Typography>
        <Typography variant={'subtitle1'} align={'left'}>
          Please enter your details.
        </Typography>
      </div>
      <TextField
        label="Username"
        variant="outlined"
        required
        value={username}
        onChange={(event) => setUsername(event.target.value)}
        error={usernameError}
        className={classes.textField}
      />
      <TextField
        label="Password"
        variant="outlined"
        required
        type={'password'}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        error={passwordError}
        className={classes.textField}
      />
      <Button
        variant={'contained'}
        onClick={loginClick}
        className={classes.loginButton}
      >
        Login
      </Button>
      <Divider />
      <MicrosoftLoginButton />
    </div>
  );
}

Login.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  snackbarSuccess: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  snackbarError,
  snackbarSuccess,
  login,
})(Login);

import thunk from 'redux-thunk';

import { applyMiddleware, compose, createStore } from 'redux';

import middleware from './middleware/index';
import rootReducer from './reducers';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(middleware, thunk)),
);

export default store;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createStyles, makeStyles } from '@mui/styles';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '20px',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function StatSummaryView() {
  const classes = useStyles();

  return <div className={classes.root}>SOON</div>;
}

StatSummaryView.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  StatSummaryView,
);

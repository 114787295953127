import React from 'react';
import PropTypes from 'prop-types';

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

function DrawerItem({ icon, link, name, toggleDrawer, nested }) {
  const handleClick = () => {
    if (window.location.pathname !== link) {
      window.location.replace(link);
    }
    toggleDrawer();
  };

  return (
    <ListItemButton sx={nested ? { pl: 4 } : {}} onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  );
}

DrawerItem.propTypes = {
  nested: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
};

export default DrawerItem;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import {
  getAllPauses,
  updatePauseAPI,
} from 'service/serviceHelper/request/pause';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '20px',
      display: 'grid',
      gridGap: '20px',
      gridTemplateColumns: '1fr',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    card: {},
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PauseViewStop({ token, logout }) {
  const classes = useStyles();
  const [pauses, setPauses] = useState([]);

  useEffect(() => {
    getAllPauses(token)
      .then((res) => {
        setPauses(res.filter((pause) => !pause.endDate));
      })
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  }, [token]);

  const stopPause = (id, date, pause) => {
    pause.endDate = new Date(date.getTime() + 15 * 60000);
    updatePauseAPI(id, pause, token)
      .then(() => {
        setPauses(pauses.filter((p) => p._id !== id));
      })
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  };

  return (
    <div className={classes.root}>
      {pauses.map((pause) => {
        const date = new Date(pause.startDate);
        return (
          <Card className={classes.card} key={pause._id}>
            <CardHeader title={<Typography> {pause.email}</Typography>} />
            <CardContent>
              <Typography>Jour: {date.toLocaleDateString('fr-FR')}</Typography>
              <Typography>Heure: {date.toLocaleTimeString('fr-FR')}</Typography>
            </CardContent>
            <CardActions>
              <Button
                variant={'contained'}
                onClick={() => stopPause(pause._id, date, pause)}
              >
                Stop Pause
              </Button>
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
}

PauseViewStop.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  PauseViewStop,
);

import LocalLogin from 'service/login/localLogin';

import { LOGIN, LOGOUT, REGISTER } from '../constants/action-types';

const initialState = {
  logged: LocalLogin.hasLocalUser(),
  token: LocalLogin.getLocalUserToken(),
  refresh: LocalLogin.getLocalUserRefreshToken(),
};

function loginReducer(state = initialState, action) {
  if (action.type === LOGIN) {
    return Object.assign({}, state, {
      logged: action.payload.logged,
      token: action.payload.token,
      refresh: action.payload.refresh,
    });
  } else if (action.type === REGISTER) {
    return Object.assign({}, state, {
      logged: action.payload.logged,
      token: action.payload.token,
      refresh: action.payload.refresh,
    });
  } else if (action.type === LOGOUT) {
    return Object.assign({}, state, {
      logged: false,
      token: undefined,
      refresh: undefined,
    });
  }

  return state;
}

export default loginReducer;

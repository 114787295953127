const { serviceHelper } = require('../serviceHelper');

async function loginRequest(username, password) {
  return serviceHelper('/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).catch((err) => Promise.reject(err.data));
}

async function registerRequest(username, password) {
  return serviceHelper('/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).catch((err) => Promise.reject(err.data));
}

async function externalLoginRequest(username, accessToken, provider) {
  return serviceHelper(`/auth/external/${provider}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, accessToken }),
  }).catch((err) => Promise.reject(err.data));
}

export { externalLoginRequest, loginRequest, registerRequest };

import * as React from 'react';
import PropTypes from "prop-types";

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        imageButton: {
            position: 'relative',
            height: 250,
            [theme.breakpoints.down('md')]: {
                height: 200,
            },
            [theme.breakpoints.down('sm')]: {
                height: 150,
            },
            '&:hover, &.Mui-focusVisible': {
                zIndex: 1,
                '& .MuiImageBackdrop-root': {
                    opacity: 0.15,
                },
                '& .MuiImageMarked-root': {
                    opacity: 0,
                },
                '& .MuiTypography-root': {
                    border: '4px solid currentColor',
                },
            },
        },
        imageSrc: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: 'cover',
            backgroundPosition: 'center 40%',
        },
        image: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.common.white,
        },
        imageBackdrop: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.common.black,
            opacity: 0.4,
            transition: theme.transitions.create('opacity'),
        },
        imageMarked: {
            height: 3,
            width: 18,
            backgroundColor: theme.palette.common.white,
            position: 'absolute',
            bottom: -2,
            left: 'calc(50% - 9px)',
            transition: theme.transitions.create('opacity'),
        },
    }))

function ImageButton({item}) {
    const classes = useStyles();

    return (
        <ButtonBase
            className={classes.imageButton}
            onClick={() => {
                window.location.href = item.url;
            }}
            href={item.url}
            focusRipple
        >
            <span className={classes.imageSrc} style={{backgroundImage: `url(${item.image_url})`}}/>
            <span className={classes.imageBackdrop}/>
            <span className={classes.image}>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            }}
                        >
                            {item.title}
                            <span className={classes.imageMarked}/>
                        </Typography>
                    </span>
        </ButtonBase>
    );
}

ImageButton.propTypes = {
    item: PropTypes.shape({
        image_url: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
    })
};

export default ImageButton

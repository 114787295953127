import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function StatusSelector({ searchStatus, setSearchStatus }) {
  return (
    <FormControl fullWidth>
      <InputLabel>Status</InputLabel>
      <Select
        variant={'filled'}
        value={searchStatus}
        label="Status"
        onChange={(event) => setSearchStatus(event.target.value)}
      >
        <MenuItem value={''}>None</MenuItem>
        <MenuItem value={'present'}>Present</MenuItem>
        <MenuItem value={'absent'}>Absent</MenuItem>
        <MenuItem value={'retard'}>Retard</MenuItem>
        <MenuItem value={'excuse'}>Excuse</MenuItem>
        <MenuItem value={'_'}>Not Set</MenuItem>
      </Select>
    </FormControl>
  );
}

StatusSelector.propTypes = {
  searchStatus: PropTypes.string.isRequired,
  setSearchStatus: PropTypes.func.isRequired,
};

export default StatusSelector;

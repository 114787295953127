export function searchParamsToObject(searchParams) {
  return Object.fromEntries(new URLSearchParams(searchParams));
}

export function updateSearchParams(key, value, searchParams, setSearchParams) {
  const searchAsObject = searchParamsToObject(searchParams);
  setSearchParams({
    ...searchAsObject,
    [key]: value,
  });
}

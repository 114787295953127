import React from 'react';
import { words } from 'capitalize';
import PropTypes from 'prop-types';

import { Card, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      height: '200px',
      display: 'grid',
      gridColumn: '1fr',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      padding: '10px',
    },
  }),
);

function StudentCard({ children, student }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.body}>
        <Typography variant={'subtitle1'}>
          {words(student.firstname + ' ' + student.lastname)}
        </Typography>
      </div>
      {children}
    </Card>
  );
}

StudentCard.propTypes = {
  children: PropTypes.element.isRequired,
  student: PropTypes.object.isRequired,
};

export default StudentCard;

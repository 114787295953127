import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';

import PauseViewStop from 'views/49-3/PauseViewStop/PauseViewStop';
import GlobalView from 'views/GlobalView';
import HomeView from 'views/HomeView/HomeView';
import LoginView from 'views/LoginView/LoginView';
import PauseView from 'views/PauseView/PauseView';
import PresenceView from 'views/PresenceView/PresenceView';
import StudentView from 'views/StudentView/StudentView';
import PauseSummaryView from 'views/SummaryView/PauseSummaryView/PauseSummaryView';
import StatSummaryView from 'views/SummaryView/StatSummaryView/StatSummaryView';

import AdminRoute from 'components/global/AdminRoute/AdminRoute';

import { socket, SocketContext } from 'context/socket';

let theme = createTheme({
  palette: {
    background: {
      default: '#EAEAEA',
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <SocketContext.Provider value={socket}>
          <Router>
            <Routes>
              <Route path={'/'} element={<GlobalView />}>
                <Route
                  index
                  element={
                    <AdminRoute>
                      <HomeView />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/pause'}
                  element={
                    <AdminRoute>
                      <PauseView />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/presence'}
                  element={
                    <AdminRoute>
                      <PresenceView />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/summary/pauses'}
                  element={
                    <AdminRoute>
                      <PauseSummaryView />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/summary/stats'}
                  element={
                    <AdminRoute>
                      <StatSummaryView />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/49-3'}
                  element={
                    <AdminRoute>
                      <PauseViewStop />
                    </AdminRoute>
                  }
                />
                <Route
                  path={'/students'}
                  element={
                    <AdminRoute>
                      <StudentView />
                    </AdminRoute>
                  }
                />
              </Route>
              <Route path={'/login'} element={<LoginView />} />
              <Route path={'*'} element={<Navigate replace to="/" />} />
            </Routes>
          </Router>
        </SocketContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;

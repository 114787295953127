import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';

import GlobalAppBar from 'components/global/GlobalAppBar/GlobalAppBar';
import GlobalDrawer from 'components/global/GlobalDrawer/GlobalDrawer';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
});

function GlobalView({ classes }) {
  const [drawer, openDrawer] = useState(false);

  const toggleDrawer = () => {
    openDrawer(!drawer);
  };

  return (
    <div className={classes.root}>
      <GlobalAppBar toggleDrawer={toggleDrawer} />
      <GlobalDrawer open={drawer} toggleDrawer={toggleDrawer} />
      <Outlet />
    </div>
  );
}

GlobalView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GlobalView);

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { faCoffee, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PresenceAction from 'components/presence/PresenceAction/PresenceAction';
import SearchHeader from 'components/SearchHeader/SearchHeader';
import StatusSelector from 'components/SearchHeader/StatusSelector/StatusSelector';
import StudentCard from 'components/StudentCard/StudentCard';

import { SocketContext } from 'context/socket';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import {
  searchParamsToObject,
  updateSearchParams,
} from 'service/searchParam/searchParam';
import { getStudentsWithPresenceByDayAndActiveStatus } from 'service/serviceHelper/request/student';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    searchHeader: {
      padding: '20px',
      paddingBottom: 0,
    },
    studentList: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      gridGap: '20px',
    },
    tabLink: {
      backgroundColor: '#FFF',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PresenceView({ token, logout, snackbarError }) {
  const classes = useStyles();
  const socket = useContext(SocketContext);
  const date = useMemo(() => new Date(), []);
  const [searchParams, setSearchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    if (!searchParams.get('moment'))
      setSearchParams({ ['moment']: date.getHours() > 13 ? 'pm' : 'am' });
  }, [date]);

  useEffect(() => {
    let date_ = date;
    date_.setHours(9);
    getStudentsWithPresenceByDayAndActiveStatus(token, date_, true)
      .then((res) => setStudents(res))
      .catch(({ code, msg }) => {
        snackbarError(msg);
        if (code === 401) logout();
      });
  }, [date, token, logout]);

  useEffect(() => {
    const { moment, status, search } = searchParamsToObject(searchParams);

    setFilteredStudents(
      students.filter(
        (student) =>
          // verify if the name correspond to the search
          (student.firstname + ' ' + student.lastname).includes(search || '') &&
          // verify status
          (!status ||
            // verify status for morning
            (moment === 'am' &&
              (student.amPresence === status ||
                (status === '_' && student.amPresence === null))) ||
            // verify status for afternoon
            (moment === 'pm' &&
              (student.pmPresence === status ||
                (status === '_' && student.pmPresence === null)))),
      ),
    );
  }, [students, searchParams]);

  useEffect(() => {
    socket.on('update_presence', async (data) => {
      data = await JSON.parse(data);
      setStatus(data.email, data.moment, data.status);
    });

    return () => {
      socket.off('update_presence');
    };
  }, [students]);

  const setStatus = (email, moment, status) => {
    const student = students.find((s) => email === s.email);
    let new_students = students.filter((s) => email !== s.email);

    if (!student) {
      snackbarError('Internal error: refresh to see the update!');
      return;
    }
    switch (moment) {
      case 'am':
        student.amPresence = status;
        break;
      case 'noon':
        student.noonPresence = status;
        break;
      case 'pm':
        student.pmPresence = status;
        break;
      case 'night':
        student.nightPresence = status;
        break;
      default:
        break;
    }

    new_students.push(student);
    new_students.sort((a, b) => (a.email > b.email ? 1 : -1));
    setStudents(new_students);
  };

  const updateQueryParams = (key, value) => {
    updateSearchParams(key, value, searchParams, setSearchParams);
  };

  return (
    <div className={classes.root}>
      <TabContext value={searchParams.get('moment') || 'am'}>
        <div className={classes.searchHeader}>
          <SearchHeader
            filterFunction={(value) =>
              updateQueryParams('search', value.toLowerCase())
            }
            date={date}
            rightComponent={
              <StatusSelector
                setSearchStatus={(value) => updateQueryParams('status', value)}
                searchStatus={searchParams.get('status') || ''}
              />
            }
          />
        </div>
        <TabList
          onChange={(event, value) => updateQueryParams('moment', value)}
          variant={'fullWidth'}
          className={classes.tabLink}
        >
          <Tab
            icon={<FontAwesomeIcon icon={faCoffee} />}
            iconPosition="start"
            label="Matin"
            value="am"
          />
          <Tab
              icon={<FontAwesomeIcon icon={faCoffee} />}
              iconPosition="start"
              label="Midi"
              value="noon"
          />
          <Tab
            icon={<FontAwesomeIcon icon={faSun} />}
            iconPosition="start"
            label="Aprem"
            value="pm"
          />
          <Tab
              icon={<FontAwesomeIcon icon={faCoffee} />}
              iconPosition="start"
              label="Soir"
              value="night"
          />
        </TabList>
        <TabPanel value="am">
          <div className={classes.studentList}>
            {filteredStudents.map((student) => (
              <StudentCard student={student} key={student.email}>
                <PresenceAction
                  email={student.email}
                  moment={'am'}
                  status={student.amPresence}
                  date={date}
                />
              </StudentCard>
            ))}
          </div>
        </TabPanel>
        <TabPanel value="noon">
          <div className={classes.studentList}>
            {filteredStudents.map((student) => (
                <StudentCard student={student} key={student.email}>
                  <PresenceAction
                      email={student.email}
                      moment={'noon'}
                      status={student.noonPresence}
                      date={date}
                  />
                </StudentCard>
            ))}
          </div>
        </TabPanel>
        <TabPanel value="pm">
          <div className={classes.studentList}>
            {filteredStudents.map((student) => (
              <StudentCard student={student} key={student.email}>
                <PresenceAction
                  email={student.email}
                  moment={'pm'}
                  status={student.pmPresence}
                  date={date}
                />
              </StudentCard>
            ))}
          </div>
        </TabPanel>
        <TabPanel value="night">
          <div className={classes.studentList}>
            {filteredStudents.map((student) => (
                <StudentCard student={student} key={student.email}>
                  <PresenceAction
                      email={student.email}
                      moment={'night'}
                      status={student.nightPresence}
                      date={date}
                  />
                </StudentCard>
            ))}
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

PresenceView.propTypes = {
  snackbarError: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarError })(
  PresenceView,
);

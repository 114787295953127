export const DATA_LOADED = 'DATA_LOADED';
export const ACTION_ERROR = 'ACTION_ERROR';

export const RESET_FILTER = 'RESET_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';

export const CUSTOMISATION_DARK_MODE = 'CUSTOMISATION_DARK_MODE';
export const CUSTOMISATION_LANGUAGE = 'CUSTOMISATION_LANGUAGE';

export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_WARNING = 'SNACKBAR_WARNING';
export const SNACKBAR_INFO = 'SNACKBAR_INFO';
export const SNACKBAR_RESET = 'SNACKBAR_RESET';

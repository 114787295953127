import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CircularProgress, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { logout } from 'redux/actions/loginAction';
import { snackbarError } from 'redux/actions/snackbarAction';

import { timestampToStr } from 'service/date/timestampToStr';
import { getCurrentPauseByEmail } from 'service/serviceHelper/request/pause';

import PauseActionButton from './PauseActionButton';

const useStyles = makeStyles(() =>
  createStyles({
    action: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '10px 0',
    },
    progress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}

function PauseAction({
  email,
  token,
  pause,
  isInPause,
  startPause,
  endPause,
  logout,
}) {
  const classes = useStyles();
  const [pauseStartTime, setPauseStartTime] = useState(undefined);
  const [pauseId, setPauseId] = useState(undefined);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    if (isInPause) {
      getCurrentPauseByEmail(email, token)
        .then((res) => {
          if (res && pause._id === res._id) {
            setPauseStartTime(new Date(res.startDate));
            setPauseId(res._id);
          }
          setLoading(false);
        })
        .catch(({ code, msg }) => {
          snackbarError(msg);
          if (code === 401) logout();
        });
    } else {
      setLoading(false);
    }
  }, [isInPause]);

  if (isLoading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.action}>
      {pauseStartTime !== undefined ? (
        <div>
          <Typography variant={'body1'}>Pause start at</Typography>
          <Typography variant={'body1'}>
            {pauseStartTime.toLocaleTimeString()}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant={'body1'}>Time pause:</Typography>
          <Typography variant={'body1'}>
            {timestampToStr(
              pause ? new Date(pause.endDate) - new Date(pause.startDate) : 0,
            )}
          </Typography>
        </div>
      )}
      <PauseActionButton
        email={email}
        isInPause={isInPause && (pause ? pauseId === pause._id : false)}
        isInCurrentPause={isInPause}
        totalPauseTime={
          pause ? new Date(pause.endDate) - new Date(pause.startDate) : 0
        }
        startPause={startPause}
        endPause={endPause}
        pauseStartTime={pauseStartTime}
        pauseId={pauseId}
      />
    </div>
  );
}

PauseAction.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  pause: PropTypes.object,
  isInPause: PropTypes.bool.isRequired,
  startPause: PropTypes.func.isRequired,
  endPause: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { snackbarError, logout })(PauseAction);

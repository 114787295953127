import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import {
  faSignInAlt,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { logout } from 'redux/actions/loginAction';
import { snackbarSuccess } from 'redux/actions/snackbarAction';

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    menuitem: {
      display: 'grid',
      gridTemplateColumns: '1fr 4fr',
      gridGap: theme.spacing(1),
    },
    menuItemName: {
      justifySelf: 'flex-end',
    },
  }),
);

function mapStateToProps(state) {
  return {
    logged: state.loginReducer.logged,
  };
}

function GlobalLogin({ logged, logout, snackbarSuccess }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogoutClick = () => {
    snackbarSuccess('Disconnected');
    logout();
    setAnchorEl(null);
  };

  const handleLoginClick = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color={'inherit'}
        aria-label={'login-button'}
      >
        <FontAwesomeIcon icon={faUserCircle} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ className: classes.menu }}
      >
        {logged ? (
          <MenuItem>
            <ButtonBase
              onClick={handleLogoutClick}
              className={classes.menuitem}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              <Typography className={classes.menuItemName}> Logout </Typography>
            </ButtonBase>
          </MenuItem>
        ) : (
          <MenuItem>
            <ButtonBase
              onClick={handleLoginClick}
              className={classes.menuitem}
              href={'/login'}
            >
              <FontAwesomeIcon icon={faSignInAlt} />
              <Typography className={classes.menuItemName}> Login </Typography>
            </ButtonBase>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

GlobalLogin.propTypes = {
  logged: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  snackbarSuccess: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { logout, snackbarSuccess })(
  GlobalLogin,
);

const { serviceHelper } = require('../serviceHelper');

async function getAllStudents(token) {
  return serviceHelper('/students', {
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.data);
}

async function updateStudent(token, email, student) {
  return serviceHelper(`/students/${email}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(student),
  });
}

async function getStudentsWithPresenceByDayAndActiveStatus(
  token,
  date,
  active,
) {
  return serviceHelper(
    `/students/presence?date=${date}` + (active ? '&active=true' : ''),
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
}

async function createStudentsFromEmail(token, json) {
  return serviceHelper('/students/from-email', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: json,
  });
}

export {
  createStudentsFromEmail,
  getAllStudents,
  getStudentsWithPresenceByDayAndActiveStatus,
  updateStudent,
};

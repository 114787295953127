import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import SearchBar from '../global/SearchBar/SearchBar';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '20px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    switch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function SearchHeader({ filterFunction, date, rightComponent }) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant={'h4'} align={'center'}>
        {date.toLocaleDateString()}
      </Typography>
      <SearchBar update={filterFunction} />
      {rightComponent}
    </div>
  );
}

SearchHeader.propTypes = {
  filterFunction: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  rightComponent: PropTypes.element.isRequired,
};

export default SearchHeader;

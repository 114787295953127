export function timestampToStr(timestamp) {
  if (!timestamp) return '0s';

  timestamp = timestamp / 1000;
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = Math.ceil(timestamp % 60);

  return `${minutes.toString().padStart(2, '0')}min ${seconds
    .toString()
    .padStart(2, '0')}s`;
}
